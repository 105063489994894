<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="1200px"
      title="高拍仪上传"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <!-- 高拍仪视频预览区 -->
      <div id="view">
        <div v-if="isShowView1">
          <img id="view1" src="http://127.0.0.1:38088/video=stream&camidx=0" alt="主摄像头">
        </div>
        <div v-if="isShowView2">
          <img id="view2" alt="副摄像头">
        </div>
      </div>
      <!-- 缩略图 -->
      <div id="suoluetu" />
      <!-- 功能按钮 -->
      <div id="myactive">
        <a-button type="danger" @click="closeView1" v-if="isShowView1">关闭主摄像头视频</a-button>
        <a-button type="primary" @click="openView1" v-else>打开主摄像头视频</a-button>
        <a-button type="primary" @click="view1Scan" v-if="isShowView1">主摄像头拍照</a-button>
        <a-button type="primary" @click="flatScan" v-if="isShowView1">展平拍照</a-button>
        <a-button type="primary" @click="rotate(90)" v-if="isShowView1">左转</a-button>
        <a-button type="primary" @click="rotate(270)" v-if="isShowView1">右转</a-button>
        <!--        <a-button type="primary" @click="amplify" v-if="isShowView1">放大</a-button>-->
        <!--        <a-button type="primary" @click="reduce" v-if="isShowView1">缩小</a-button>-->
        <a-button type="danger" @click="closeView2" v-if="isShowView2">关闭副摄像头视频</a-button>
        <a-button type="primary" @click="openView2" v-else>打开副摄像头视频</a-button>
        <a-button type="primary" @click="view2Scan" v-if="isShowView2">副摄像头拍照</a-button>
        <a-button type="primary" @click="previewImage">预览照片</a-button>
        <br>
      </div>
    </a-modal>

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="images"
    />
  </div>
</template>

<script>
import axios from 'axios'
import PreviewImage from '@/components/PreviewImage'
import { uploadGaoPaiYi } from '@/api/order'
export default {
  name: 'GaoPaiYi',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  components: {
    PreviewImage
  },
  data() {
    return {
      isShowView1: true,
      isShowView2: false,
      previewVisible: false,
      submitting: false,
      images: [],
      imageUrls: [],
      corp: 0 // 图像放大比例，范围0~9，0：原图，9最大显示
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    // 打开主摄像头
    openView1() {
      this.isShowView1 = true
      this.$nextTick(() => {
        document.getElementById('view1').src = 'http://127.0.0.1:38088/video=stream&camidx=0?1'
      })
    },

    // 关闭主摄像头
    closeView1() {
      axios.post('http://127.0.0.1:38088/video=close', JSON.stringify({ camidx: '0' })).then(() => {
        document.getElementById('view1').src = ''
        this.isShowView1 = false
      })
    },

    // 打开副摄像头
    openView2() {
      this.isShowView2 = true
      this.$nextTick(() => {
        document.getElementById('view2').src = 'http://127.0.0.1:38088/video=stream&camidx=1?1'
      })
    },

    // 关闭副摄像头
    closeView2() {
      axios.post('http://127.0.0.1:38088/video=close', JSON.stringify({ camidx: '1' })).then(() => {
        document.getElementById('view2').src = ''
        this.isShowView2 = false
      })
    },

    // 旋转
    rotate(v) {
      axios.post('http://127.0.0.1:38088/video=rotate', JSON.stringify({ camidx: '0', rotate: String(v) }))
    },

    // 放大
    amplify() {
      if (this.corp < 9) {
        const data = this.corp + 1
        axios.post('http://127.0.0.1:38088/stream=zoominout', JSON.stringify({ action: '0', corp: String(data) })).then((res) => {
          debugger
          if (res.data.code === '0') {
            this.corp = data
          }
        })
      }
    },

    // 缩小
    reduce() {
      if (this.corp > 0) {
        const data = this.corp - 1
        axios.post('http://127.0.0.1:38088/stream=zoominout', JSON.stringify({ corp: String(data), action: '0' })).then((res) => {
          if (res.data.code === '0') {
            this.corp = data
          }
        })
      }
    },

    // 添加缩略图
    addImage(imgBase64) {
      const img = document.createElement('img')
      img.src = 'data:image/jpg;base64,' + imgBase64
      img.style.width = '80px'
      img.style.height = '80px'
      img.style.margin = '2px'
      this.images.push({ url: img.src })
      this.imageUrls.push(img.src)
      document.getElementById('suoluetu').appendChild(img)
    },

    // 主头拍照
    view1Scan() {
      const data = {
        filepath: 'base64',
        rotate: '0',
        cutpage: '0',
        camidx: '0',
        ColorMode: '0',
        quality: '1'
      }
      axios.post('http://127.0.0.1:38088/video=grabimage', JSON.stringify(data)).then((res) => {
        this.addImage(res.data.photoBase64)
      })
    },

    // 副头拍照
    view2Scan() {
      const data = {
        filepath: 'base64',
        rotate: '0',
        cutpage: '0',
        camidx: '1',
        ColorMode: '0',
        quality: '3'
      }
      axios.post('http://127.0.0.1:38088/video=grabimage', JSON.stringify(data)).then((res) => {
        this.addImage(res.data.photoBase64)
      })
    },

    // 展平拍照
    flatScan() {
      const data = {
        filepath: '',
        rotate: '0',
        camidx: '0',
        cutpage: '0',
        autoflat: {
          flat: '1',
          leftfilepath: 'D://left.jpg',
          rightfilepath: 'D://right.jpg',
          removefinger: '1',
          doublepage: '1'
        }
      }
      axios.post('http://127.0.0.1:38088/video=autoflat', JSON.stringify(data)).then((res) => {
        this.addImage(res.data.photoBase64)
        this.addImage(res.data.leftphotoBase64)
        this.addImage(res.data.rightphotoBase64)
      })
    },

    previewImage() {
      this.previewVisible = true
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.submitting = true
      if (this.imageUrls.length === 0) {
        this.$warning({
          title: '请拍摄需要上传的图片',
          content: ''
        })
        this.submitting = false
        return
      }
      uploadGaoPaiYi(this.id, { urls: this.imageUrls }).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
        this.submitting = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
#view {
  display: flex;
  justify-content: center;
}

/* 视频预览 */
#view1, #view2{
  height: 400px;
  width: 100%;
  padding: 10px;
  margin-right: 5px;
}
/* 缩略图 */
#suoluetu{
  width: 100%;
  height: 100px;
  border: 1px solid #c5c5ce;
  overflow: scroll;

  img {
    margin: 2px;
  }
}
#suoluetu img{
  margin-right: 10px;
}
/* 操作按钮 */
#myactive{
  button {
    margin: 5px;
  }
}
/* 操作日志 */
#mylog{
  border: 1px solid black;
  padding: 10px;
  margin-top: 10px;
  overflow: auto;
}
</style>
